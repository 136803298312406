import React from 'react'

export default function Domain() {
  return (
    <div>
    <div className="installer_css">
 <code>This Domain is not varified</code>
 <br />
 <br />
 <code>
     For Varification of your domain PlZ contact To ItInfo Team....
  
 </code>
</div>

   </div>
  )
}
